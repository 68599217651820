p,
a,
span,
h1,
li {
  margin: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: white;
  font-family: "Cairo";
  font-size: 24px;
}

.workItem ul li {
  color: white;
}
